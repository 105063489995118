import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import ContactForm from "@components/sections/LazyContactForm";
import MapView from "@components/sections/LazyMapView";
import ContactUs from "@components/sections/ContactUs";
import SmallBanner from "@components/common/SmallBanner";

const Contacto = () => {
    return (
        <Layout>
            <Seo
                title="Contacto"
                description="¿Quieres contactar con iOpos? Rellena el formulario y en breve te contactaremos"
            />

            <SmallBanner
                title="Contacta con nosotros"
                subtitle="¿Tienes alguna duda o sugerencia? No lo dudes y contáctanos."
                filename="bgs/bgContact"
            />
            <ContactUs className="bg-gray-100 bg-opacity-50" />
            <MapView />
            <ContactForm className="bg-gray-100 bg-opacity-50" />
        </Layout>
    );
};

export default Contacto;
